/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

.main-bg {
  background-color: #00a3e9;

}

.hover-zoom {
  transition: transform 0.3s;
}

.hover-zoom:hover {
  -ms-transform: scale(1.02);
  /* IE 9 */
  -webkit-transform: scale(1.02);
  /* Safari 3-8 */
  transform: scale(1.02);
}

.right-align {
  float: right;
}

@media screen and (max-height: 800px) {
  .card-view {
    height: 500px !important;
    margin: 0px !important;
    width: 100% !important;

  }
}

@media only screen and (max-width: 800px) {
  .card-view {
    height: 300px !important;
    margin: 0px !important;
    width: 100% !important;
  }
}


.card-view-thumbnail {
  width: 256px !important;
  height: 256px !important;
}

.centered-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

}


.hide-from-user{
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(100%);
  white-space: nowrap;
}

.active {
  font-weight: bold !important;
  color: #00a3e9 !important;
}